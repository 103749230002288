import Vue from 'vue'
import VueRouter from 'vue-router'
import Content from '../views/Content.vue'
import Kmen from '../views/Kmen.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home'
  },
  {
    path: '/kalendar',
    name: 'Calendar',
    // route level code-splitting
    // this generates a separate chunk (calendar.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "calendar" */ '../views/Calendar.vue')
  },
  {
    path: '/projekty-a-vyzvy/:slug?',
    name: 'Projects',
    // route level code-splitting
    // this generates a separate chunk (calendar.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "calendar" */ '../views/Projects.vue'),
    props: true
  },{
    path: '/casopis-roversky-kmen',
    name: 'Kmen',
    component: Kmen
  },
  {
    path: '/pridat-stranku',
    name: 'Editor',
    // route level code-splitting
    // this generates a separate chunk (add-content.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "add-content" */ '../views/AddContent.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Home',
        })
      }
      next()
    }
  },
  {
    path: '/nastaveni',
    name: 'Admin',
    // route level code-splitting
    // this generates a separate chunk (admin.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Home',
        })
      }
      next()
    }
  },
  {
    path: '/:slug',
    name: 'Content',
    component: Content
  },
  {
    path: '/:slug/upravit',
    name: 'Edit Content',
    // route level code-splitting
    // this generates a separate chunk (edit-content.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "edit-content" */ '../views/EditContent.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Home',
        })
      }
      next()
    }
  },
  {
    path: '/:slug/upravit-html',
    name: 'Edit HTML',
    component: () => import(/* webpackChunkName: "edit-html" */ '../views/EditHtml.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'Home',
        })
      }
      next()
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'Home' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    }
  }
})

export default router
